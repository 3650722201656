import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import Titlebar from "common/components/Titlebar";
import "common/assets/css/main-page.css";
import { useState } from "react";
import AppointmentWrapper from "../containers/Home/appointment.style";
import axios from 'axios';
import Seo from "components/seo";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import the default styles
import { ColorRing } from 'react-loader-spinner'; 

const Appointment = () => {
  const seoData = {
    title: "Book an Appointment - Radiance Hair Studio",
    description: "Ready to transform your look? Book an appointment at Radiance Hair Studio and experience top-notch hair solutions. Your journey to beauty begins here.",
    keywords: ["Book an Appointment"],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "additionalType": "https://www.radiancehairstudio.com/appointment.php",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "description": "Ready to transform your look? Book an appointment at Radiance Hair Studio and experience top-notch hair solutions. Your journey to beauty begins here.",
    "disambiguatingDescription": "Book an Appointment",
    "image": "https://www.radiancehairstudio.com/home/home-image-18.webp",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "mainEntityOfPage": "https://www.radiancehairstudio.com/appointment.php",
    "serviceType": "Hair Studio"
  };  

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    services: "",
    date: "",
    availability: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [showThankYou, setShowThankYou] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading

  const handleChange = (value, data) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!formData.phone.trim()) {
      newErrors.phone = 'Phone is required';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const token = 'CmcXDorMLw8mj8ZlJI1aE1f2U1foRfn8'; // Replace 'your_token_here' with your actual token
        await axios.post(
          'https://www.radiancehairstudio.com/api/appointment.php',
          formData,
          {
            headers: {
              'token': token
            }
          }
        );
        console.log('Data submitted successfully');
        setShowThankYou(true);
        setFormData({
          name: "",
          phone: "",
          email: "",
          services: "",
          date: "",
          availability: "",
          message: "",
        });
      } catch (error) {
        console.error('Error submitting data:', error);
      }finally {
        setLoading(false); // Hide progress indicator
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <ThemeProvider theme={interiorTheme} className="hideExtraContacts">
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <Titlebar title="Book Your Appointment" />
          <AppointmentWrapper>
            <div id="appointmentPage">
              <div className="myContainer pb-5">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="text-center text-inverse">
                      Discover Your Perfect Look with <br />Gorgeous <a href="/" className="text-black">Hair Wigs</a>
                    </h3>
                    <form
                      className="appointmentForm"
                      id="needs-validation"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="form-group">
                            <label
                              className="text-inverse"
                              htmlFor="validationCustom01"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              placeholder="Enter your name"
                              name="name"
                              value={formData.name}
                              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            />
                            {errors.name && <p className="mb-0 error-red">{errors.name}</p>}
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="form-group">
                            <label
                              className="text-inverse"
                              htmlFor="validationCustom02"
                            >
                              Phone
                            </label>
                            <PhoneInput
                              country={'in'}
                              value={formData.phone}
                              onChange={handleChange}
                              inputProps={{
                                name: 'phone',
                                id: 'validationCustom02',
                                placeholder: "Enter your phone no",
                              }}
                            />
                            {errors.phone && <p className="mb-0 error-red">{errors.phone}</p>}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12 col-12">
                          <div className="form-group">
                            <label
                              className="text-inverse"
                              htmlFor="inputEmail4"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              id="inputEmail4"
                              placeholder="Enter your email"
                              value={formData.email}
                              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            />
                            {/* {errors.email && <p className="mb-0">{errors.email}</p>} */}
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="form-group">
                            <label className="text-inverse" htmlFor="services">
                              Services
                            </label>
                            <select
                              className="custom-select d-block form-control"
                              id="services"
                              name="services"
                              value={formData.services}
                              onChange={(e) => setFormData({ ...formData, services: e.target.value })}
                            >
                              <option value="">Select Services</option>
                              <option value="Hair Bonding">Hair Bonding</option>
                              <option value="Hair Bonding Package">
                                Hair Bonding Package
                              </option>
                              <option value="Hair Clipping">
                                Hair Clipping
                              </option>
                              <option value="Hair Colour Services">
                                Hair Colour Services
                              </option>
                              <option value="Shampoo Setting">
                                Shampoo Setting
                              </option>
                              <option value="Consultation &amp; Free Demo">
                                Consultation &amp; Free Demo
                              </option>
                              <option value="New Hair Unit">
                                New Hair Unit
                              </option>
                              <option value="Others">Others</option>
                            </select>
                            {/* {errors.services && <p className="mb-0">{errors.services}</p>} */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="form-group">
                            <label
                              className="text-inverse"
                              htmlFor="validationCustom03"
                            >
                              Select Date{" "}
                            </label>
                            <div
                              className="input-group date"
                              id="datetimepicker1"
                            >
                              <input
                                type="date"
                                className="form-control input-lg"
                                placeholder="dd-mm-yyyy"
                                name="date"
                                value={formData.date}
                                onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                              />

                              <span className="input-group-addon">
                                <span className="glyphicon glyphicon-calendar"></span>
                              </span>
                            </div>
                            {/* {errors.date && <p className="mb-0">{errors.date}</p>} */}
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="form-group">
                            <label
                              className="text-inverse"
                              htmlFor="availability"
                            >
                              Available on
                            </label>
                            <select
                              className="custom-select d-block form-control"
                              id="availability"
                              name="availability"
                              value={formData.availability}
                              onChange={(e) => setFormData({ ...formData, availability: e.target.value })}
                            >
                              <option value="">Available On</option>
                              <option value="10.30 AM">10.30 AM</option>
                              <option value="11.30 AM">11.30 AM</option>
                              <option value="12.30 AM">12.30 AM</option>
                              <option value="01.30 PM">01.30 PM</option>
                              <option value="02.30 PM">02.30 PM</option>
                              <option value="03.30 PM">03.30 PM</option>
                              <option value="04.30 PM">04.30 PM</option>
                              <option value="05.30 PM">05.30 PM</option>
                              <option value="06.30 PM">06.30 PM</option>
                              <option value="07.30 PM">07.30 PM</option>
                            </select>
                            {/* {errors.availability && <p className="mb-0">{errors.availability}</p>} */}
                            {/* <div className="invalid-feedback">
                              Please selected any option.
                            </div> */}
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-6 col-12">
                          <div className="form-group">
                            <label
                              className="text-inverse"
                              htmlFor="message"
                            >
                              Message
                            </label>
                            <textarea
                              type="textarea"
                              className="form-control"
                              name="message"
                              id="message"
                              placeholder="Describe your requrement"
                              value={formData.message}
                              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                              rows="5"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-sm-12 col-12 text-center">
                        {loading ? (
                    
                    <ColorRing
                     visible={true}
                     height="80"
                     width="80"
                     ariaLabel="color-ring-loading"
                     wrapperStyle={{}}
                     wrapperClass="color-ring-wrapper"
                     colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']} // Add a class to ensure it is correctly positioned
                     />
                  
                     
                   ) : (
                    <button className="btn btn-info" type="submit">
                    Submit form
                  </button>
                   )}
                      
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </AppointmentWrapper>

          {showThankYou && (
            <div className="thankyou-popup">
              <div className="thankyou-content">
                <p>Thank you for your submission!</p>
                <button className="close-btn" onClick={() => setShowThankYou(false)}>Close</button>
              </div>
            </div>
          )}

        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};

export default Appointment;
